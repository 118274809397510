import { actionPartnerAddTypes } from 'constants/actionPartnerAddTypes';

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_SHOW_LOADING, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_STATUS, payload });

export const setLocation = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_LOCATION, payload });

export const setName = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_NAME, payload });

export const setSurname = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_SURNAME, payload });

export const setMiddleName = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_MIDDLE_NAME, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_SHOW_ERRORS, payload });

export const setReferralCode = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_REFERRAL_CODE, payload });

export const setDateOfBirth = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DATE_OF_BIRTH, payload });

export const setDocumentNumber = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_NUMBER, payload });

export const setDocumentIssueDate = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_ISSUE_DATE, payload });

export const setDocumentValidDate = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_VALID_DATE, payload });

export const setPersonalNumber = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_PERSONAL_NUMBER, payload });

export const setPersonalTaxNumber = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_PERSONAL_TAX_NUMBER, payload });

export const setDocumentFrontImageFile = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_FRONT_IMAGE_FILE, payload });

export const setDocumentFrontFile = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_FRONT_FILE, payload });

export const setDocumentBackImageFile = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_BACK_IMAGE_FILE, payload });

export const setDocumentBackFile = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_BACK_FILE, payload });

export const setDocumentAdditionalImageFile = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_ADDITIONAL_IMAGE_FILE, payload });

export const setDocumentAdditionalFile = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_DOCUMENT_ADDITIONAL_FILE, payload });

export const setActivePolygons = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_ACTIVE_POLYGONS, payload });

export const setAddress = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_ADDRESS, payload });

export const setTShirtSize = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_TSHIRT_SIZE, payload });

export const setPassword = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_PASSWORD, payload });

export const setRepeatPassword = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_REPEAT_PASSWORD, payload });

export const setEmail = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_EMAIL, payload });

export const setPhone = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_PHONE, payload });

export const setSocialNetworks = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_SOCIAL_NETWORKS, payload });

export const setAvatarFile = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_AVATAR_FILE, payload });

export const setAvatarImage = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_AVATAR_IMAGE, payload });

export const setServiceType = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_SERVICE_TYPE, payload });

export const setIban = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_IBAN, payload });

export const setBic = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_BIC, payload });

export const setBankName = (dispatch, payload) =>
    dispatch({ type: actionPartnerAddTypes.SET_BANK_NAME, payload });