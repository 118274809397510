import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, promocodeAddReducer } from './reducers/PromocodeAddReducer';
import usePromocodeAddActions from 'hooks/usePromocodeAddActions';

const promocodeAddContext = createContext({
    state: initialState,
    actions: {},
});

const usePromocodeAddContext = () => {
    const context = useContext(promocodeAddContext);

    if (!context) {
        throw new Error(
            'promocodeAddContext must be used within a PromocodeAddProvider.'
        );
    }

    return context;
};

const PromocodeAddProvider = ({ children }) => {
    const [promocodeAddState, dispatch] = useReducer(promocodeAddReducer, initialState);
    const actions = usePromocodeAddActions(dispatch);
    const value = useMemo(() => ({ promocodeAddState, actions }), [promocodeAddState, actions]);
    console.log('🚀 ~ PromocodeAddProvider ~ value:', value);

    return (
        <promocodeAddContext.Provider value={value}>
            {children}
        </promocodeAddContext.Provider>
    );
};

export { usePromocodeAddContext, PromocodeAddProvider };