import { useNavigate } from "react-router-dom";
import {useCallback} from 'react';
import { useAuthContext } from "contexts/AuthContext";
import { getSessionToken } from "utils/session";
import { createQueryStringWithURLSearchParams } from 'utils/createQueryString';
import { Routes } from 'constants/routes';
import { generateAuthToken } from 'utils/token';

export default function useAjaxCall() {
    const {
        authState,
        actions: { clearAuthData },
    } = useAuthContext();

    const navigate = useNavigate();

    const makeAjaxCall = useCallback(async(url, params, admin = false) => {
        if(admin === true){
            if(authState.isLogged === false){
                clearAuthData();
                navigate(Routes.LOGIN);
            }

            params = Object.assign(params, {
                adminAuthToken: generateAuthToken(getSessionToken('authToken')),
                location: getSessionToken('authLocation'),
                language: getSessionToken('authLanguage'),
            });
        }

        try{
            const response = await fetch(
                process.env.REACT_APP_API_URL + url,
                {
                    method: 'POST',
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: createQueryStringWithURLSearchParams(params),
                },
            );

            // parse data
            const data = await response.json();

            if(data.result === false
                && data.logout === true
                && admin === true
                && authState.isLogged){
                clearAuthData();
                navigate(Routes.LOGIN);
            }

            return data;
        }catch (e) {
            console.log(e);
        }
    }, [authState.isLogged, clearAuthData, navigate]);

    const makeAjaxCallFileUpload = useCallback(async(url, file, admin = false) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("location", authState.location ? authState.location : null);
        formData.append("language", getSessionToken('authLanguage'));
        formData.append("adminAuthToken", generateAuthToken(getSessionToken('authToken')));

        const response = await fetch(
            process.env.REACT_APP_API_URL + url,
            {
                method: 'POST',
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                },
                body: formData,
            },
        );

        // parse data
        const data = await response.json();

        return data;
    }, [authState.location]);

    return { makeAjaxCall, makeAjaxCallFileUpload };
}