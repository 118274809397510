export const actionServiceTypeAddTypes = {
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_LABEL: 'SET_LABEL',
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_STATUS: 'SET_STATUS',
    SET_MIN_PRICE: 'SET_MIN_PRICE',
    SET_BASE_PERCENT: 'SET_BASE_PERCENT',
    SET_SERVICE_TYPE_DATA: 'SET_SERVICE_TYPE_DATA',
    SET_BASE_PERCENT_CHOOSE: 'SET_BASE_PERCENT_CHOOSE',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_CONTENT: 'SET_CONTENT',
    SET_H1: 'SET_H1',
    SET_META_DESCRIPTION: 'SET_DESCRIPTION',
    SET_SLUG: 'SET_SLUG',
    SET_SLUG_PRICES: 'SET_SLUG_PRICE',
    SET_PAGE_TITLE_PRICE: 'SET_PAGE_TITLE_PRICE',
    SET_CONTENT_PRICE: 'SET_CONTENT_PRICE',
    SET_H1_PRICE: 'SET_H1_PRICE',
    SET_META_DESCRIPTION_PRICE: 'SET_DESCRIPTION_PRICE',
    SET_MIN_TIME: 'SET_MIN_TIME',
    SET_PARENT: 'SET_PARENT',
    SET_ICON_IMAGE: 'SET_ICON_IMAGE',
    SET_ICON_IMAGE_FILE: 'SET_ICON_IMAGE_FILE',
    SET_SHOW_ICON_IMAGE_ERROR: 'SET_SHOW_ICON_IMAGE_ERROR',
};