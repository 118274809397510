import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, seoStaticPageAddReducer } from './reducers/SeoStaticPageAddReducer';
import useSeoStaticPageAddActions from 'hooks/useSeoStaticPageAddActions';

const seoStaticPageAddContext = createContext({
    state: initialState,
    actions: {},
});

const useSeoStaticPageAddContext = () => {
    const context = useContext(seoStaticPageAddContext);

    if (!context) {
        throw new Error(
            'seoStaticPageAddContext must be used within a SeoStaticPageAddProvider.'
        );
    }

    return context;
};

const SeoStaticPageAddProvider = ({ children }) => {
    const [seoStaticPageAddState, dispatch] = useReducer(seoStaticPageAddReducer, initialState);
    const actions = useSeoStaticPageAddActions(dispatch);
    const value = useMemo(() => ({ seoStaticPageAddState, actions }), [seoStaticPageAddState, actions]);
    console.log('🚀 ~ SeoStaticPageAddProvider ~ value:', value);

    return (
        <seoStaticPageAddContext.Provider value={value}>
            {children}
        </seoStaticPageAddContext.Provider>
    );
};

export { useSeoStaticPageAddContext, SeoStaticPageAddProvider };