export const actionSeoDistrictAddTypes = {
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_STATUS: 'SET_STATUS',
    SET_SLUG: 'SET_SLUG',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_META_DESCRIPTION: 'SET_META_DESCRIPTION',
    SET_H1: 'SET_H1',
    SET_CONTENT: 'SET_CONTENT',
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_SHOW_IMAGE_ERROR: 'SET_SHOW_IMAGE_ERROR',
    SET_CATEGORY_IMAGE: 'SET_CATEGORY_IMAGE',
    SET_CATEGORY_IMAGE_FILE: 'SET_CATEGORY_IMAGE_FILE',
    SET_DISTRICT_DATA: 'SET_DISTRICT_DATA',
};