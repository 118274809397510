import { Suspense } from 'react';
import {Outlet} from "react-router-dom";

/*const MainLayout = ({ children }) => (
    <>
        <Header />
        <main className="main">
            {children}
        </main>
        <Footer />
    </>
);*/

const MainLayout = ({ children }) => {
    return (
        <>
            <Suspense>
                <Outlet />
                {children}
            </Suspense>
        </>
    )
}

export default MainLayout;