import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationPL from './locales/pl.json';
import translationEN from './locales/en.json';
import translationRU from './locales/ru.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    pl: {
        translation: translationPL,
    },
    en: {
        translation: translationEN,
    },
    ru: {
        translation: translationRU,
    },
};

if (!i18n.isInitialized) {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: localStorage.getItem('authLanguage') || process.env.REACT_APP_BASE_LOCALE || "en",
            fallbackLng: localStorage.getItem('authLanguage') || process.env.REACT_APP_BASE_LOCALE || "en",
            //debug: true,
            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });
}

export default i18n;