import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, questionAddReducer } from './reducers/QuestionAddReducer';
import useQuestionAddActions from 'hooks/useQuestionAddActions';

const questionAddContext = createContext({
    state: initialState,
    actions: {},
});

const useQuestionAddContext = () => {
    const context = useContext(questionAddContext);

    if (!context) {
        throw new Error(
            'questionAddContext must be used within a QuestionAddProvider.'
        );
    }

    return context;
};

const QuestionAddProvider = ({ children }) => {
    const [questionAddState, dispatch] = useReducer(questionAddReducer, initialState);
    const actions = useQuestionAddActions(dispatch);
    const value = useMemo(() => ({ questionAddState, actions }), [questionAddState, actions]);
    console.log('🚀 ~ QuestionAddProvider ~ value:', value);

    return (
        <questionAddContext.Provider value={value}>
            {children}
        </questionAddContext.Provider>
    );
};

export { useQuestionAddContext, QuestionAddProvider };