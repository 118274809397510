import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, seoDistrictAddReducer } from './reducers/SeoDistrictAddReducer';
import useSeoDistrictAddActions from 'hooks/useSeoDistrictAddActions';

const seoDistrictAddContext = createContext({
    state: initialState,
    actions: {},
});

const useSeoDistrictAddContext = () => {
    const context = useContext(seoDistrictAddContext);

    if (!context) {
        throw new Error(
            'seoDistrictAddContext must be used within a SeoDistrictAddProvider.'
        );
    }

    return context;
};

const SeoDistrictAddProvider = ({ children }) => {
    const [seoDistrictAddState, dispatch] = useReducer(seoDistrictAddReducer, initialState);
    const actions = useSeoDistrictAddActions(dispatch);
    const value = useMemo(() => ({ seoDistrictAddState, actions }), [seoDistrictAddState, actions]);
    console.log('🚀 ~ SeoDistrictAddProvider ~ value:', value);

    return (
        <seoDistrictAddContext.Provider value={value}>
            {children}
        </seoDistrictAddContext.Provider>
    );
};

export { useSeoDistrictAddContext, SeoDistrictAddProvider };