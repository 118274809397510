export const actionPartnerAddTypes = {
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_NAME: 'SET_NAME',
    SET_SURNAME: 'SET_SURNAME',
    SET_MIDDLE_NAME: 'SET_MIDDLE_NAME',
    SET_REFERRAL_CODE: 'SET_REFERRAL_CODE',
    SET_DATE_OF_BIRTH: 'SET_DATE_OF_BIRTH',
    SET_DOCUMENT_NUMBER: 'SET_DOCUMENT_NUMBER',
    SET_DOCUMENT_ISSUE_DATE: 'SET_DOCUMENT_ISSUE_DATE',
    SET_DOCUMENT_VALID_DATE: 'SET_DOCUMENT_VALID_DATE',
    SET_PERSONAL_NUMBER: 'SET_PERSONAL_NUMBER',
    SET_PERSONAL_TAX_NUMBER: 'SET_PERSONAL_TAX_NUMBER',
    SET_DOCUMENT_FRONT_IMAGE_FILE: 'SET_DOCUMENT_FRONT_IMAGE_FILE',
    SET_DOCUMENT_FRONT_FILE: 'SET_DOCUMENT_FRONT_FILE',
    SET_DOCUMENT_BACK_IMAGE_FILE: 'SET_DOCUMENT_BACK_IMAGE_FILE',
    SET_DOCUMENT_BACK_FILE: 'SET_DOCUMENT_BACK_FILE',
    SET_DOCUMENT_ADDITIONAL_IMAGE_FILE: 'SET_DOCUMENT_ADDITIONAL_IMAGE_FILE',
    SET_DOCUMENT_ADDITIONAL_FILE: 'SET_DOCUMENT_ADDITIONAL_FILE',
    SET_IBAN: 'SET_IBAN',
    SET_BIC: 'SET_BIC',
    SET_BANK_NAME: 'SET_BANK_NAME',
    SET_ADDRESS: 'SET_ADDRESS',
    SET_APARTMENT_NUMBER: 'SET_APARTMENT_NUMBER',
    SET_ACTIVE_POLYGONS: 'SET_DISTRICTS',
    SET_TSHIRT_SIZE: 'SET_TSHIRT_SIZE',
    SET_EMAIL: 'SET_EMAIL',
    SET_PHONE: 'SET_PHONE',
    SET_PASSWORD: 'SET_PASSWORD',
    SET_REPEAT_PASSWORD: 'SET_REPEAT_PASSWORD',
    SET_SOCIAL_NETWORKS: 'SET_SOCIAL_NETWORKS',
    SET_AVATAR_FILE: 'SET_AVATAR_FILE',
    SET_AVATAR_IMAGE: 'SET_AVATAR_IMAGE',
    SET_SERVICE_TYPE: 'SET_SERVICE_TYPE',
};