import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Routes } from 'constants/routes';
import { useAuthContext } from "contexts/AuthContext";
import { getPartnerTokenData } from "utils/token";

const LoginRoute = () => {
    const {
        authState,
    } = useAuthContext();

    if(authState.token && authState.id){
        // validate token data
        var tokenData = getPartnerTokenData(authState.token);
        if(authState.id === tokenData.id){
            return <Navigate to={Routes.DASHBOARD} />
        }
    }

    return <Outlet />;
};

export default LoginRoute;