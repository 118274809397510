import { actionAgreementAddTypes } from 'constants/actionAgreementAddTypes';

export const initialState = {
    id: null,
    showMainError: false,
    showLoading: false,
    type: 'customer',
    status: 1,
    errors: [],
    title: [],
    shortTitle: [],
    slug: [],
    metaDescription: [],
    pageTitle: [],
    content: [],
};

export const agreementAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionAgreementAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionAgreementAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionAgreementAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionAgreementAddTypes.SET_TYPE:
            return { ...state, type: action.payload };
        case actionAgreementAddTypes.SET_AGREEMENT_DATA:
            return {
                ...state,
                id: action.payload.id,
                status: action.payload.status,
                type: action.payload.type,
                title: action.payload.titleByLanguage,
                shortTitle: action.payload.shortTitleByLanguage,
                metaDescription: action.payload.metaDescriptionByLanguage,
                pageTitle: action.payload.pageTitleByLanguage,
                content: action.payload.contentByLanguage,
                slug: action.payload.slugByLanguage,
            };
        case actionAgreementAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
        case actionAgreementAddTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: {
                    ...state.pageTitle,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionAgreementAddTypes.SET_META_DESCRIPTION:
            return {
                ...state,
                metaDescription: {
                    ...state.metaDescription,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionAgreementAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionAgreementAddTypes.SET_TITLE:
            return {
                ...state,
                title: {
                    ...state.title,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionAgreementAddTypes.SET_SHORT_TITLE:
            return {
                ...state,
                shortTitle: {
                    ...state.shortTitle,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionAgreementAddTypes.SET_CONTENT:
            return {
                ...state,
                content: {
                    ...state.content,
                    [action.payload.language]: action.payload.value,
                },
            };
    }
};