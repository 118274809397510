import { Navigate, Outlet } from "react-router-dom";
import { Routes } from 'constants/routes';
import { useAuthContext } from "contexts/AuthContext";
import { getPartnerTokenData } from "utils/token";

const PrivateRoute = () => {
    const {
        authState,
    } = useAuthContext();

    if(!authState.token || !authState.id){
        return <Navigate to={Routes.LOGIN} />
    }

    // validate token data
    var tokenData = getPartnerTokenData(authState.token);
    if(authState.id !== tokenData.id){
        return <Navigate to={Routes.LOGIN} />
    }

    return <Outlet />;
};

export default PrivateRoute;