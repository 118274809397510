import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout/index.jsx';
import LoginLayout from 'layouts/LoginLayout/index.jsx';
import DashboardLayout from 'layouts/DashboardLayout/index.jsx';

import PrivateRoute from "./privateRoute";
import LoginRoute from "./loginRoute";
import { Routes } from 'constants/routes';
import { getLink } from 'utils/links';

import { AuthProvider } from "contexts/AuthContext";
import { OrderAddProvider } from "contexts/OrderAddContext";
import { PartnerAddProvider } from "contexts/PartnerAddContext";
import { OrderProvider } from 'contexts/OrderContext';
import { PromocodeAddProvider } from 'contexts/PromocodeAddContext';
import { ServiceAddProvider } from 'contexts/ServiceAddContext';
import { ServiceTypeAddProvider } from 'contexts/ServiceTypeAddContext';
import { QuestionAddProvider } from 'contexts/QuestionAddContext';
import { QuestionTypeAddProvider } from 'contexts/QuestionTypeAddContext';
import { SeoDistrictAddProvider } from 'contexts/SeoDistrictAddContext';
import { SeoStaticPageAddProvider } from 'contexts/SeoStaticPageAddContext';
import { AgreementAddProvider } from 'contexts/AgreementAddContext';
import { SeoOrderPageAddProvider } from 'contexts/SeoOrderPageAddContext';

//import DashboardPage from "pages/DashboardPage";
//import OrderEditPage from "pages/Orders/OrderEditPage";
const DashboardPage = lazy(() => import('pages/DashboardPage'));

const LoginPage = lazy(() => import('pages/Login/LoginPage'));
const LogutPage = lazy(() => import('pages/Login/LogoutPage'));

const OrdersPage = lazy(() => import('pages/Orders/OrdersPage'));
const OrderAddPage = lazy(() => import('pages/Orders/OrderAddPage'));
const OrderDetailsPage = lazy(() => import('pages/Orders/OrderDetailsPage'));

const OrderEditPage = lazy(() => import('pages/Orders/OrderEditPage'));
const OrderCopyPage = lazy(() => import('pages/Orders/OrderCopyPage'));

const PartnersPage = lazy(() => import('pages/Partners/PartnersPage'));
const PartnerDetailsPage = lazy(() => import('pages/Partners/PartnerDetailsPage'));
const PartnerAddPage = lazy(() => import('pages/Partners/PartnerAddPage'));

const FrontendPage = lazy(() => import('pages/FrontendPage'));

const CustomersPage = lazy(() => import('pages/Customers/CustomersPage'));
const CustomerDetailsPage = lazy(() => import('pages/Customers/CustomerDetailsPage'));

const ReviewsPage = lazy(() => import('pages/ReviewsPage'));

const PromocodesPage = lazy(() => import('pages/Promocodes/PromocodesPage'));
const PromocodeAddPage = lazy(() => import('pages/Promocodes/PromocodeAddPage'));
const PromocodeDetailsPage = lazy(() => import('pages/Promocodes/PromocodeDetailsPage'));
const PromocodeEditPage = lazy(() => import('pages/Promocodes/PromocodeEditPage'));

const ServicesPage = lazy(() => import('pages/Services/ServicesPage'));
const ServiceDetailsPage = lazy(() => import('pages/Services/ServiceDetailsPage'));
const ServiceAddPage = lazy(() => import('pages/Services/ServiceAddPage'));
const ServiceEditPage = lazy(() => import('pages/Services/ServiceEditPage'));
const ServiceTypesPage = lazy(() => import('pages/Services/ServiceTypesPage'));
const ServiceTypeAddPage = lazy(() => import('pages/Services/ServiceTypeAddPage'));
const ServiceTypeDetailsPage = lazy(() => import('pages/Services/ServiceTypeDetailsPage'));
const ServiceTypeEditPage = lazy(() => import('pages/Services/ServiceTypeEditPage'));

const QuestionsPage = lazy(() => import('pages/Questions/QuestionsPage'));
const QuestionAddPage = lazy(() => import('pages/Questions/QuestionAddPage'));
const QuestionEditPage = lazy(() => import('pages/Questions/QuestionEditPage'));
const QuestionTypesPage = lazy(() => import('pages/Questions/QuestionTypesPage'));
const QuestionTypeAddPage = lazy(() => import('pages/Questions/QuestionTypeAddPage'));
const QuestionTypeEditPage = lazy(() => import('pages/Questions/QuestionTypeEditPage'));

const WarehousePage = lazy(() => import('pages/Warehouse/WarehousePage'));

const MessagesPage = lazy(() => import('pages/Messages/MessagesPage'));

const CertificatesPage = lazy(() => import('pages/Certificates/CertificatesPage'));

const AnalyticsPage = lazy(() => import('pages/Analytics/AnalyticsPage'));

const TutorialsPage = lazy(() => import('pages/Tutorials/TutorialsPage'));

const AgreementsPage = lazy(() => import('pages/Agreements/AgreementsPage'));
const AgreementAddPage = lazy(() => import('pages/Agreements/AgreementAddPage'));
const AgreementEditPage = lazy(() => import('pages/Agreements/AgreementEditPage'));

const SeoPage = lazy(() => import('pages/Seo/SeoPage'));
const SeoDistrictsPage = lazy(() => import('pages/Seo/SeoDistrictsPage'));
const SeoDistrictAddPage = lazy(() => import('pages/Seo/SeoDistrictAddPage'));
const SeoDistrictEditPage = lazy(() => import('pages/Seo/SeoDistrictEditPage'));
const SeoOrderPagesPage = lazy(() => import('pages/Seo/SeoOrderPagesPage'));
const SeoOrderPagesAddPage = lazy(() => import('pages/Seo/SeoOrderPagesAddPage'));
const SeoServiceTypesPage = lazy(() => import('pages/Seo/SeoServiceTypesPage'));
const SeoStaticPagesPage = lazy(() => import('pages/Seo/SeoStaticPagesPage'));
const SeoStaticPageAddPage = lazy(() => import('pages/Seo/SeoStaticPageAddPage'));
const SeoStaticPageEditPage = lazy(() => import('pages/Seo/SeoStaticPageEditPage'));

const CalendarPage = lazy(() => import('pages/CalendarPage'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthProvider><MainLayout /></AuthProvider>,
        children: [
            {
                index: true,
                element: <FrontendPage />,
            },
            {
                path: Routes.LOGIN,
                element: <LoginRoute />,
                children: [
                    {
                        index: true,
                        element: <LoginLayout><LoginPage /></LoginLayout>,
                    },
                ],
            },
            {
                path: Routes.LOGOUT,
                element: <LogutPage />,
            },
            {
                path: '/',
                element: <DashboardLayout><PrivateRoute /></DashboardLayout>,
                children: [
                    {
                        path: Routes.DASHBOARD,
                        element: <DashboardPage />,
                    },
                    {
                        path: Routes.CUSTOMERS,
                        children: [
                            {
                                index: true,
                                element: <CustomersPage />,
                            },
                            {
                                path: ':page',
                                element: <CustomersPage />,
                            },
                            {
                                path: 'details',
                                children: [
                                    { path: ':id', element: <CustomerDetailsPage /> }
                                ],
                            },
                        ],
                    },
                    {
                        path: Routes.ORDERS,
                        children: [
                            {
                                index: true,
                                element: <OrdersPage />,
                            },
                            {
                                path: ':page',
                                element: <OrdersPage />,
                            },
                            {
                                path: 'add',
                                element: <OrderAddProvider><OrderAddPage /></OrderAddProvider>,
                            },
                            {
                                path: 'edit/:id',
                                element: <OrderAddProvider><OrderEditPage /></OrderAddProvider>,
                            },
                            {
                                path: 'copy/:id',
                                element: <OrderAddProvider><OrderCopyPage /></OrderAddProvider>,
                            },
                            {
                                path: 'details/:id',
                                element: <OrderProvider><OrderDetailsPage /></OrderProvider>
                            }
                        ],
                    },
                    {
                        path: Routes.PARTNERS,
                        children: [
                            {
                                index: true,
                                element: <PartnersPage />,
                            },
                            {
                                path: ':page',
                                element: <PartnersPage />,
                            },
                            {
                                path: 'details',
                                children: [
                                    { path: ':id', element: <PartnerDetailsPage /> }
                                ],
                            },
                            {
                                path: 'add',
                                element: <PartnerAddProvider><PartnerAddPage /></PartnerAddProvider>,
                            },
                        ],
                    },
                    {
                        path: Routes.REVIEWS,
                        children: [
                            {
                                index: true,
                                element: <ReviewsPage />,
                            },
                            {
                                path: ':page',
                                element: <ReviewsPage />,
                            },
                            {
                                path: 'details/:id',
                                element: <OrderProvider><OrderDetailsPage /></OrderProvider>
                            }
                        ],
                    },
                    {
                        path: Routes.PROMOCODES,
                        children: [
                            {
                                index: true,
                                element: <PromocodesPage />,
                            },
                            {
                                path: 'add',
                                element: <PromocodeAddProvider><PromocodeAddPage /></PromocodeAddProvider>,
                            },
                            {
                                path: ':page',
                                element: <PromocodesPage />,
                            },
                            {
                                path: 'details/:id',
                                element: <PromocodeDetailsPage />
                            },
                            {
                                path: 'edit/:id',
                                element: <PromocodeAddProvider><PromocodeEditPage /></PromocodeAddProvider>
                            }
                        ],
                    },
                    {
                        path: getLink('services'),
                        children: [
                            {
                                index: true,
                                element: <ServicesPage />,
                            },
                            {
                                path: 'add',
                                element: <ServiceAddProvider><ServiceAddPage /></ServiceAddProvider>,
                            },
                            {
                                path: 'types/add',
                                element: <ServiceTypeAddProvider><ServiceTypeAddPage /></ServiceTypeAddProvider>,
                            },
                            {
                                path: 'types',
                                element: <ServiceTypesPage />,
                            },
                            {
                                path: 'types/:page',
                                element: <ServiceTypesPage />,
                            },
                            {
                                path: 'types/details/:id',
                                element: <ServiceTypeDetailsPage />,
                            },
                            {
                                path: 'types/edit/:id',
                                element: <ServiceTypeAddProvider><ServiceTypeEditPage /></ServiceTypeAddProvider>,
                            },
                            {
                                path: ':page',
                                element: <ServicesPage />,
                            },
                            {
                                path: 'details/:id',
                                element: <ServiceDetailsPage />
                            },
                            {
                                path: 'edit/:id',
                                element: <ServiceAddProvider><ServiceEditPage /></ServiceAddProvider>
                            }
                        ],
                    },
                    {
                        path: Routes.QUESTIONS,
                        children: [
                            {
                                index: true,
                                element: <QuestionsPage />,
                            },
                            {
                                path: ':page',
                                element: <QuestionsPage />,
                            },
                            {
                                path: 'types',
                                element: <QuestionTypesPage />,
                            },
                            {
                                path: 'types/:page',
                                element: <QuestionTypesPage />,
                            },
                            {
                                path: 'types/edit/:id',
                                element: <QuestionTypeAddProvider><QuestionTypeEditPage /></QuestionTypeAddProvider>,
                            },
                            {
                                path: 'types/add',
                                element: <QuestionTypeAddProvider><QuestionTypeAddPage /></QuestionTypeAddProvider>,
                            },
                            {
                                path: 'add',
                                element: <QuestionAddProvider><QuestionAddPage /></QuestionAddProvider>,
                            },
                            {
                                path: 'edit/:id',
                                element: <QuestionAddProvider><QuestionEditPage /></QuestionAddProvider>,
                            },
                        ],
                    },
                    {
                        path: Routes.WAREHOUSE,
                        children: [
                            {
                                index: true,
                                element: <WarehousePage />,
                            },
                            {
                                path: ':page',
                                element: <WarehousePage />,
                            },
                        ],
                    },
                    {
                        path: Routes.MESSAGES,
                        children: [
                            {
                                index: true,
                                element: <MessagesPage />,
                            },
                            {
                                path: ':page',
                                element: <MessagesPage />,
                            },
                        ],
                    },
                    {
                        path: Routes.CERTIFICATES,
                        children: [
                            {
                                index: true,
                                element: <CertificatesPage />,
                            },
                            {
                                path: ':page',
                                element: <CertificatesPage />,
                            },
                        ],
                    },
                    {
                        path: Routes.ANALYTICS,
                        children: [
                            {
                                index: true,
                                element: <AnalyticsPage />,
                            },
                            {
                                path: ':page',
                                element: <AnalyticsPage />,
                            },
                        ],
                    },
                    {
                        path: Routes.TUTORIALS,
                        children: [
                            {
                                index: true,
                                element: <TutorialsPage />,
                            },
                            {
                                path: ':page',
                                element: <TutorialsPage />,
                            },
                        ],
                    },
                    {
                        path: Routes.AGREEMENTS,
                        children: [
                            {
                                index: true,
                                element: <AgreementsPage />,
                            },
                            {
                                path: ':page',
                                element: <AgreementsPage />,
                            },
                            {
                                path: 'add',
                                element: <AgreementAddProvider><AgreementAddPage /></AgreementAddProvider>,
                            },
                            {
                                path: 'edit/:id',
                                element: <AgreementAddProvider><AgreementEditPage /></AgreementAddProvider>,
                            },
                        ],
                    },
                    {
                        path: Routes.SEO,
                        children: [
                            {
                                index: true,
                                element: <SeoPage />,
                            },
                            {
                                path: 'districts',
                                element: <SeoDistrictsPage />,
                            },
                            {
                                path: 'districts/:page',
                                element: <SeoDistrictsPage />,
                            },
                            {
                                path: 'districts/add',
                                element: <SeoDistrictAddProvider><SeoDistrictAddPage /></SeoDistrictAddProvider>,
                            },
                            {
                                path: 'districts/edit/:id',
                                element: <SeoDistrictAddProvider><SeoDistrictEditPage /></SeoDistrictAddProvider>,
                            },
                            {
                                path: 'orderPages/add',
                                element: <SeoOrderPageAddProvider><SeoOrderPagesAddPage /></SeoOrderPageAddProvider>,
                            },
                            {
                                path: 'orderPages',
                                element: <SeoOrderPagesPage />,
                            },
                            {
                                path: 'orderPages/:page',
                                element: <SeoOrderPagesPage />,
                            },
                            {
                                path: 'services/types',
                                element: <SeoServiceTypesPage />,
                            },
                            {
                                path: 'staticPages',
                                element: <SeoStaticPagesPage />,
                            },
                            {
                                path: 'staticPages/:page',
                                element: <SeoStaticPagesPage />,
                            },
                            {
                                path: 'staticPages/add',
                                element: <SeoStaticPageAddProvider><SeoStaticPageAddPage /></SeoStaticPageAddProvider>,
                            },
                            {
                                path: 'staticPages/edit/:id',
                                element: <SeoStaticPageAddProvider><SeoStaticPageEditPage /></SeoStaticPageAddProvider>,
                            },
                        ],
                    },
                    {
                        path: Routes.CALENDAR,
                        children: [
                            {
                                index: true,
                                element: <CalendarPage />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

export default router;