import { actionSeoDistrictAddTypes } from 'constants/actionSeoDistrictAddTypes';

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_SHOW_LOADING, payload });

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_STATUS, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_SLUG, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_SHOW_ERRORS, payload });

export const setPageTitle = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_PAGE_TITLE, payload });

export const setMetaDescription = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_META_DESCRIPTION, payload });

export const setH1 = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_H1, payload });

export const setContent = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_CONTENT, payload });

export const setCategoryImage = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_CATEGORY_IMAGE, payload });

export const setCategoryImageFile = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_CATEGORY_IMAGE_FILE, payload });

export const setShowImageError = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_SHOW_IMAGE_ERROR, payload });

export const setDistrictData = (dispatch, payload) =>
    dispatch({ type: actionSeoDistrictAddTypes.SET_DISTRICT_DATA, payload });