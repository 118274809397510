import { useMemo } from 'react';
import * as actions from 'contexts/actions/agreementAddActions';

const useAgreementAddActions = (dispatch) => {
    return useMemo(() => {
        const boundActions = {};

        for (const key in actions) {
            boundActions[key] = (...args) => actions[key](dispatch, ...args);
        }

        return boundActions;
    }, [dispatch]);
};

export default useAgreementAddActions;