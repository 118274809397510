import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, questionTypeAddReducer } from './reducers/QuestionTypeAddReducer';
import useQuestionTypeAddActions from 'hooks/useQuestionTypeAddActions';

const questionTypeAddContext = createContext({
    state: initialState,
    actions: {},
});

const useQuestionTypeAddContext = () => {
    const context = useContext(questionTypeAddContext);

    if (!context) {
        throw new Error(
            'questionTypeAddContext must be used within a QuestionTypeAddProvider.'
        );
    }

    return context;
};

const QuestionTypeAddProvider = ({ children }) => {
    const [questionTypeAddState, dispatch] = useReducer(questionTypeAddReducer, initialState);
    const actions = useQuestionTypeAddActions(dispatch);
    const value = useMemo(() => ({ questionTypeAddState, actions }), [questionTypeAddState, actions]);
    console.log('🚀 ~ QuestionTypeAddProvider ~ value:', value);

    return (
        <questionTypeAddContext.Provider value={value}>
            {children}
        </questionTypeAddContext.Provider>
    );
};

export { useQuestionTypeAddContext, QuestionTypeAddProvider };