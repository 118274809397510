import { actionServiceAddTypes } from 'constants/actionServiceAddTypes';

export const initialState = {
    label: [],
    slug: [],
    pageTitle: [],
    metaDescription: [],
    pageContent: [],
    status: 1,
    showLoading: false,
    price: 0,
    time: 0,
    max: 1,
    unit: 'thing',
    errors: [],
    periodicity: 0,
    fixPrice: 1,
    fullPriceToPartner: 0,
    discount: 1,
    customerOrder: 0,
    adminOrder: 0,
    idServiceType: null,
    showMainError: false,
    showServiceTypeError: false,
    iconImage: null,
    iconImageFile: null,
    showIconImageError: false,
};

export const serviceAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionServiceAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionServiceAddTypes.SET_SHOW_SERVICE_TYPE_ERROR:
            return { ...state, showServiceTypeError: action.payload };
        case actionServiceAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionServiceAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionServiceAddTypes.SET_PRICE:
            return { ...state, price: action.payload };
        case actionServiceAddTypes.SET_TIME:
            return { ...state, time: action.payload };
        case actionServiceAddTypes.SET_MAX:
            return { ...state, max: action.payload };
        case actionServiceAddTypes.SET_UNIT:
            return { ...state, unit: action.payload };
        case actionServiceAddTypes.SET_PERIODICITY:
            return { ...state, periodicity: action.payload };
        case actionServiceAddTypes.SET_FIX_PRICE:
            return { ...state, fixPrice: action.payload };
        case actionServiceAddTypes.SET_FULL_PRICE_TO_PARTNER:
            return { ...state, fullPriceToPartner: action.payload };
        case actionServiceAddTypes.SET_DISCOUNT:
            return { ...state, discount: action.payload };
        case actionServiceAddTypes.SET_CUSTOMER_ORDER:
            return { ...state, customerOrder: action.payload };
        case actionServiceAddTypes.SET_ADMIN_ORDER:
            return { ...state, adminOrder: action.payload };
        case actionServiceAddTypes.SET_SERVICE_TYPE:
            return { ...state, idServiceType: action.payload };
        case actionServiceAddTypes.SET_LABEL:
            return {
                ...state,
                label: {
                    ...state.label,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceAddTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: {
                    ...state.pageTitle,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceAddTypes.SET_META_DESCRIPTION:
            return {
                ...state,
                metaDescription: {
                    ...state.metaDescription,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceAddTypes.SET_PAGE_CONTENT:
            return {
                ...state,
                pageContent: {
                    ...state.pageContent,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
        case actionServiceAddTypes.SET_SERVICE_DATA:
            return {
                ...state,
                id: action.payload.id,
                status: action.payload.status,
                price: action.payload.priceInt,
                label: action.payload.labelByLanguage,
                slug: action.payload.slugByLanguage,
                metaDescription: action.payload.metaDescriptionByLanguage,
                pageContent: action.payload.pageContentByLanguage,
                pageTitle: action.payload.pageTitleByLanguage,
                max: action.payload.max,
                time: action.payload.time,
                unit: action.payload.unit,
                customerOrder: action.payload.customerOrder,
                adminOrder: action.payload.adminOrder,
                idServiceType: action.payload.idServiceType,
                periodicity: action.payload.periodicity,
                fixPrice: action.payload.fixPrice,
                fullPriceToPartner: action.payload.fullPriceToPartner,
                discount: action.payload.discount,
            };
        case actionServiceAddTypes.SET_ICON_IMAGE:
            return { ...state, iconImage: action.payload };
        case actionServiceAddTypes.SET_ICON_IMAGE_FILE:
            return { ...state, iconImageFile: action.payload };
        case actionServiceAddTypes.SET_SHOW_ICON_IMAGE_ERROR:
            return { ...state, showIconImageError: action.payload };
    }
};