import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';

export const getPartnerTokenData = (token) => {
    try {
        return jwtDecode(token);
    }catch (e) {
        return false;
    }
}

// Base64URL encode function
const base64UrlEncode = (str) => {
    return str
        .replace(/\+/g, '-')  // Replace + with -
        .replace(/\//g, '_')  // Replace / with _
        .replace(/=+$/, '');  // Remove padding
};

/**
 * Generate partner add token
 * @param tokenData
 * @param key
 * @returns {string}
 */
export const generateAuthToken = (tokenData, key = 'admin') => {
    const decoded = jwtDecode(tokenData);

    //console.log(decodeData);
    const secretKeys = {
        admin: process.env.REACT_APP_JWT_AUTH_TOKEN_SECRET,
        cdn: process.env.REACT_APP_JWT_CDN_AUTH_TOKEN_SECRET,
    };

    const secretKey = secretKeys[key] || process.env.REACT_APP_JWT_AUTH_TOKEN_SECRET;

    // Create token payload
    const payload = {
        id: decoded.id,
        idLocation: decoded.idLocation,
        permission: decoded.permission,
        validTill: decoded.validTill,
        verifyHash: decoded.verifyHash,
        prevToken: tokenData,
        exp: Math.floor(Date.now() / 1000) + (60 * 60),
    };

    // Header for JWT
    const header = {
        alg: 'HS256',
        typ: 'JWT'
    };

    // Base64URL encode header and payload
    const encodedHeader = base64UrlEncode(
        btoa(JSON.stringify(header))
    );
    const encodedPayload = base64UrlEncode(
        btoa(JSON.stringify(payload))
    );

    // Create signature
    const signatureBase = `${encodedHeader}.${encodedPayload}`;
    const signature = CryptoJS.HmacSHA256(signatureBase, secretKey);
    const encodedSignature = base64UrlEncode(
        signature.toString(CryptoJS.enc.Base64)
    );

    // Combine token parts
    const token = `${encodedHeader}.${encodedPayload}.${encodedSignature}`;

    return token;
}