import { actionSeoDistrictAddTypes } from 'constants/actionSeoDistrictAddTypes';

export const initialState = {
    id: null,
    showLoading: false,
    showMainError: false,
    status: 1,
    errors: {
        main: false,
        h1: false,
        pageTitle: false,
        slug: false,
        content: false,
    },
    slug: {},
    metaDescription: {},
    pageTitle: {},
    content: {},
    h1: {},
    categoryImage: null,
    categoryImageFile: null,
    showImageError: false,
    categoryImageChanged: false,
};

export const seoDistrictAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionSeoDistrictAddTypes.SET_DISTRICT_DATA:
            return {
                ...state,
                id: action.payload.id,
                front: action.payload.front,
                status: action.payload.status,
                h1: action.payload.h1ByLanguage,
                metaDescription: action.payload.metaDescriptionByLanguage,
                pageTitle: action.payload.pageTitleByLanguage,
                content: action.payload.contentByLanguage,
                slug: action.payload.slugByLanguage,
                //categoryImage: action.payload.categoryImage,
            };
        case actionSeoDistrictAddTypes.SET_CATEGORY_IMAGE:
            return { ...state, categoryImage: action.payload };
        case actionSeoDistrictAddTypes.SET_CATEGORY_IMAGE_FILE:
            return { ...state, categoryImageFile: action.payload };
        case actionSeoDistrictAddTypes.SET_SHOW_IMAGE_ERROR:
            return { ...state, showImageError: action.payload };
        case actionSeoDistrictAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionSeoDistrictAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionSeoDistrictAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionSeoDistrictAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
        case actionSeoDistrictAddTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: {
                    ...state.pageTitle,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoDistrictAddTypes.SET_H1:
            return {
                ...state,
                h1: {
                    ...state.h1,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoDistrictAddTypes.SET_CONTENT:
            return {
                ...state,
                content: {
                    ...state.content,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoDistrictAddTypes.SET_META_DESCRIPTION:
            return {
                ...state,
                metaDescription: {
                    ...state.metaDescription,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoDistrictAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
    }
};