import { Routes } from 'constants/routes';
//import convert from 'cyrillic-to-latin';
import slugify from 'slugify';

/**
 * Get link
 * @param key
 * @param data
 * @returns {string}
 */
export const getLink = (key, data) => {
    var link = '';

    switch (key){
        default:
            break;
        case 'partner.details':
            link = Routes.PARTNER_DETAILS + '/' + data.id;
            break;
        case 'partner.edit':
            link = Routes.PARTNER_EDIT + '/' + data.id;
            break;
        case 'partner.add':
            link = Routes.PARTNER_ADD;
            break;
        case 'customer.details':
            link = Routes.CUSTOMERS_DETAILS + '/' + data.id;
            break;
        case 'order.details':
            link = Routes.ORDER_DETAILS + '/' + data.id;
            break;
        case 'order.edit':
            link = Routes.ORDER_EDIT + '/' + data.id;
            break;
        case 'order.add':
            link = Routes.ORDERS_ADD;
            break;
        case 'order.copy':
            link = Routes.ORDER_COPY + '/' + data.id;
            break;
        case 'promocode.details':
            link = Routes.PROMOCODE_DETAILS + '/' + data.id;
            break;
        case 'promocodes.add':
            link = Routes.PROMOCODES_ADD;
            break;
        case 'promocode.edit':
            link = Routes.PROMOCODE_EDIT + '/' + data.id;
            break;
        case 'service.details':
            link = Routes.SERVICE_DETAILS + '/' + data.id;
            break;
        case 'services.add':
            link = Routes.SERVICES_ADD;
            break;
        case 'service.edit':
            link = Routes.SERVICE_EDIT + '/' + data.id;
            break;
        case 'service.types':
            link = Routes.SERVICE_TYPES;
            break;
        case 'services':
            link = Routes.SERVICES;
            break;
        case 'service.types.add':
            link = Routes.SERVICE_TYPES_ADD;
            break;
        case 'service.type.details':
            link = Routes.SERVICE_TYPE_DETAILS + '/' + data.id;
            break;
        case 'service.type.edit':
            link = Routes.SERVICE_TYPE_EDIT + '/' + data.id;
            break;
        case 'questions.add':
            link = Routes.QUESTIONS_ADD;
            break;
        case 'questions':
            link = Routes.QUESTIONS;
            break;
        case 'question.type.details':
            link = Routes.QUESTION_TYPE_DETAILS + '/' + data.id;
            break;
        case 'question.type.edit':
            link = Routes.QUESTION_TYPE_EDIT + '/' + data.id;
            break;
        case 'question.edit':
            link = Routes.QUESTION_EDIT + '/' + data.id;
            break;
        case 'seo.district.edit':
            link = Routes.SEO + '/districts/edit/' + data.id;
            break;
        case 'seo.staticPage.edit':
            link = Routes.SEO + '/staticPages/edit/' + data.id;
            break;
        case 'agreement.edit':
            link = Routes.AGREEMENTS_EDIT + '/' + data.id;
            break;
    }

    return link;
};

/**
 * Format slug
 * @param slug
 * @returns {*}
 */
export const formatSlug = (slug, language = 'pl') => {
    /*slug = convert(slug);

    return slug
        .replace(/[^a-zA-Z0-9]/g, '-')
        .replace(/-+/g, '-');*/

    return slugify(slug, {
        lower: true, // Convert to lowercase
        remove: /[*+~.()'"!:@]/g, // Remove special characters
        locale: language // Ensure Polish characters are handled
    });
}