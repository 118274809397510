import { actionAgreementAddTypes } from 'constants/actionAgreementAddTypes';

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_SHOW_LOADING, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_STATUS, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_SLUG, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_SHOW_ERRORS, payload });

export const setPageTitle = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_PAGE_TITLE, payload });

export const setMetaDescription = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_META_DESCRIPTION, payload });

export const setTitle = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_TITLE, payload });

export const setShortTitle = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_SHORT_TITLE, payload });

export const setContent = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_CONTENT, payload });

export const setType = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_TYPE, payload });

export const setAgreementData = (dispatch, payload) =>
    dispatch({ type: actionAgreementAddTypes.SET_AGREEMENT_DATA, payload });