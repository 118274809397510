import { actionPromocodeAddTypes } from 'constants/actionPromocodeAddTypes';

export const initialState = {
    code: '',
    showMainError: false,
    status: 1,
    type: 'percent',
    value: 0,
    number: 1,
    isLoading: true,
    infinitive: false,
    calendarDataFrom: null,
    calendarDataTo: null,
    dateFrom: null,
    dateTo: null,
    errors: {
        main: false,
        services: false,
        code: false,
    },
    serviceTypesServices: {},
    canEdit: true,
};

export const promocodeAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionPromocodeAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionPromocodeAddTypes.CLEAR_STATE:
            return initialState;
        case actionPromocodeAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionPromocodeAddTypes.SET_SERVICE:
            const { serviceType, service, value } = action.payload;
            var serviceTypes = state.serviceTypesServices;
            if(value === true){
                if (!serviceTypes[serviceType]) {
                    serviceTypes[serviceType] = [];
                }

                if(!serviceTypes[serviceType].includes(service)){
                    serviceTypes[serviceType].push(service);
                }
            }else{
                if(serviceTypes[serviceType]){
                    serviceTypes[serviceType] = serviceTypes[serviceType].filter(s => s !== service);

                    if(serviceTypes[serviceType].length === 0){
                        delete serviceTypes[serviceType];
                    }
                }
            }

            return {
                ...state,
                serviceTypesServices: serviceTypes,
            };

        case actionPromocodeAddTypes.SET_SERVICE_OLD:
            const updatedServices = state.services.includes(action.payload)
                ? state.services.filter(type => type !== action.payload) // Remove if exists
                : [...state.services, action.payload]; // Add if not exists
            return {
                ...state,
                services: updatedServices,
                errors: {
                    ...state.errors,
                    services: updatedServices.length === 0,
                },
            };
        case actionPromocodeAddTypes.SET_TYPE:
            return { ...state, type: action.payload };
        case actionPromocodeAddTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        case actionPromocodeAddTypes.SET_NUMBER:
            return { ...state, number: action.payload };
        case actionPromocodeAddTypes.SET_CODE:
            return { ...state, code: action.payload };
        case actionPromocodeAddTypes.SET_VALUE:
            return { ...state, value: action.payload };
        case actionPromocodeAddTypes.SET_INFINITIVE:
            return { ...state, infinitive: action.payload };
        case actionPromocodeAddTypes.SET_CALENDAR_DATA_FROM:
            return { ...state, calendarDataFrom: action.payload };
        case actionPromocodeAddTypes.SET_CALENDAR_DATA_TO:
            return { ...state, calendarDataTo: action.payload };
        case actionPromocodeAddTypes.SET_DATE_FROM:
            return { ...state, dateFrom: action.payload };
        case actionPromocodeAddTypes.SET_DATE_TO:
            return { ...state, dateTo: action.payload };
        case actionPromocodeAddTypes.SET_PROMOCODE_DATA:
            return {
                ...state,
                id: action.payload.id,
                code: action.payload.code,
                value: action.payload.value,
                number: action.payload.number,
                infinitive: action.payload.number === 0,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
                type: action.payload.type,
                status: action.payload.status,
                serviceTypesServices: {},
                canEdit: action.payload.numberOfOrders === 0,
            }
        case actionPromocodeAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.payload,
                },
            };
    }
};