//import styles from './Label.module.less';
import useAjaxCall from "utils/useAjaxRequest";

const Location = ({ location, setLocationData, setShowLoading, setShowLocations, ...props }) => {
    const { makeAjaxCall } = useAjaxCall();

    const handleLocationClick = async() => {
        setShowLoading(true);
        setShowLocations(false);

        // add new service
        var ajaxResponse = await makeAjaxCall('/admin/location/change', {
            id: location.id,
        }, true);
        
        setLocationData(ajaxResponse.data);
        window.location.reload(false);
    }

    return (
        <div onClick={handleLocationClick} className="location animate">
            <span>{location.label}</span>
        </div>
    );
}

export default Location;
