import { createContext, useContext, useMemo, useReducer } from 'react';
import { initialState, agreementAddReducer } from './reducers/AgreementAddReducer';
import useAgreementAddActions from 'hooks/useAgreementAddActions';

const agreementAddContext = createContext({
    state: initialState,
    actions: {},
});

const useAgreementAddContext = () => {
    const context = useContext(agreementAddContext);

    if (!context) {
        throw new Error(
            'agreementAddContext must be used within a AgreementAddProvider.'
        );
    }

    return context;
};

const AgreementAddProvider = ({ children }) => {
    const [agreementAddState, dispatch] = useReducer(agreementAddReducer, initialState);
    const actions = useAgreementAddActions(dispatch);
    const value = useMemo(() => ({ agreementAddState, actions }), [agreementAddState, actions]);
    console.log('🚀 ~ AgreementAddProvider ~ value:', value);

    return (
        <agreementAddContext.Provider value={value}>
            {children}
        </agreementAddContext.Provider>
    );
};

export { useAgreementAddContext, AgreementAddProvider };