export const actionSeoStaticPageAddTypes = {
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_STATUS: 'SET_STATUS',
    SET_SLUG: 'SET_SLUG',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_META_DESCRIPTION: 'SET_META_DESCRIPTION',
    SET_H1: 'SET_H1',
    SET_CONTENT: 'SET_CONTENT',
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_SEO_STATIC_PAGE_DATA: 'SET_SEO_STATIC_PAGE_DATA',
    SET_SHOW_INNER_SLUG_ERROR: 'SET_SHOW_INNER_SLUG_ERROR',
    SET_INNER_SLUG: 'SET_INNER_SLUG',
    SET_INNER_NAME: 'SET_INNER_NAME',
    SET_SHOW_INNER_NAME_ERROR: 'SET_SHOW_INNER_NAME_ERROR',
};