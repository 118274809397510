import { actionSeoOrderPageAddTypes } from 'constants/actionSeoOrderPageAddTypes';

export const initialState = {
    id: null,
    showLoading: false,
    showMainError: false,
    showInnerSlugError: false,
    showInnerNameError: false,
    status: 1,
    errors: {},
    innerSlug: null,
    innerName: null,
    slug: {},
    metaDescription: {},
    pageTitle: {},
    content: {},
    h1: {},
    serviceType: null,
    showServiceTypeError: false,
};

export const seoOrderPageAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionSeoOrderPageAddTypes.SET_SEO_ORDER_PAGE_DATA:
            return {
                ...state,
                id: action.payload.id,
                status: action.payload.status,
                h1: action.payload.h1ByLanguage,
                metaDescription: action.payload.metaDescriptionByLanguage,
                pageTitle: action.payload.pageTitleByLanguage,
                content: action.payload.contentByLanguage,
                slug: action.payload.slugByLanguage,
            };
        case actionSeoOrderPageAddTypes.SET_SHOW_SERVICE_TYPE_ERROR:
            return { ...state, showServiceTypeError: action.payload };
        case actionSeoOrderPageAddTypes.SET_SERVICE_TYPE:
            return {
                ...state,
                serviceType: action.payload === state.serviceType ? null : action.payload,
                showServiceTypeError: action.payload === state.serviceType ? true : false,
            };
        case actionSeoOrderPageAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionSeoOrderPageAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionSeoOrderPageAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionSeoOrderPageAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
        case actionSeoOrderPageAddTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: {
                    ...state.pageTitle,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoOrderPageAddTypes.SET_H1:
            return {
                ...state,
                h1: {
                    ...state.h1,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoOrderPageAddTypes.SET_CONTENT:
            return {
                ...state,
                content: {
                    ...state.content,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoOrderPageAddTypes.SET_META_DESCRIPTION:
            return {
                ...state,
                metaDescription: {
                    ...state.metaDescription,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionSeoOrderPageAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
    }
};