import styles from './DashboardLayout.module.less';
import {Link, useNavigate, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "contexts/AuthContext";
import { Routes } from 'constants/routes';
import { Header, Footer } from 'components';
import Sidebar from 'components/Sidebar';
import SvgIcon from 'components/SvgIcon';
import {useEffect} from "react";

const DashboardLayout = ({ children }) => {
    const {t} = useTranslation();

    const {
        authState,
        actions: { clearAuthData },
    } = useAuthContext();

    const navigate = useNavigate();

    const HandleLogout = (event) => {
        clearAuthData();
        navigate(Routes.LOGIN);
    }

    const location = useLocation();

    // validate if user is logged
    useEffect(() => {
        if(!authState.isLogged){
            navigate(Routes.LOGIN);
            return;
        }
    }, [location.pathname, authState.isLogged, navigate]);

    return (
        <>
            <Header />
            <main>
                <Sidebar />
                <div className={styles.dashboardMainBlock}>
                    <div className={`${styles.dashboardTopBlock}`}>
                        <div className="search">
                            <SvgIcon id="icon-search" className="icon" />
                            <input type="text" maxLength="255" className="rounded" placeholder={t('Search')} />
                        </div>
                        <div className="actions">
                            <Link to={Routes.REVIEWS} className="action action-help icon">
                                <SvgIcon id="icon-help" className="icon" />
                            </Link>
                            <Link to={Routes.REVIEWS} className="action action-notification icon">
                                <SvgIcon id="icon-notification" className="icon" />
                            </Link>
                            <Link to={Routes.REVIEWS} className="action action-setting icon">
                                <SvgIcon id="icon-setting" className="icon" />
                            </Link>
                            <div className="button mini rounded grey animate logout" onClick={HandleLogout}>
                                <SvgIcon id="icon-logout" className="icon" />
                                <span>{t('Logout')}</span>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </main>
            <Footer />
        </>
    )
}

export default DashboardLayout;