import { actionSeoStaticPageAddTypes } from 'constants/actionSeoStaticPageAddTypes';

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_SHOW_LOADING, payload });

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setShowInnerSlugError = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_SHOW_INNER_SLUG_ERROR, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_STATUS, payload });

export const setInnerSlug = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_INNER_SLUG, payload });

export const setInnerName = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_INNER_NAME, payload });

export const setShowInnerNameError = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_SHOW_INNER_NAME_ERROR, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_SLUG, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_SHOW_ERRORS, payload });

export const setPageTitle = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_PAGE_TITLE, payload });

export const setMetaDescription = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_META_DESCRIPTION, payload });

export const setH1 = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_H1, payload });

export const setContent = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_CONTENT, payload });

export const setSeoStaticPageData = (dispatch, payload) =>
    dispatch({ type: actionSeoStaticPageAddTypes.SET_SEO_STATIC_PAGE_DATA, payload });