export const actionAgreementAddTypes = {
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_STATUS: 'SET_STATUS',
    SET_SLUG: 'SET_SLUG',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_META_DESCRIPTION: 'SET_META_DESCRIPTION',
    SET_TITLE: 'SET_TITLE',
    SET_SHORT_TITLE: 'SET_SHORT_TITLE',
    SET_CONTENT: 'SET_CONTENT',
    SET_TYPE: 'SET_TYPE',
    SET_AGREEMENT_DATA: 'SET_AGREEMENT_DATA',
};