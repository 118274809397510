import {useContext, createContext, useEffect, useReducer, useMemo} from "react";
import { initialState, authReducer } from './reducers/AuthReducer';
import useAuthActions from 'hooks/useAuthActions';
import {useTranslation} from "react-i18next";

const AuthContext = createContext({
    state: initialState,
    actions: {},
});

const AuthProvider = ({ children }) => {
    const {i18n} = useTranslation();
    const [authState, dispatch] = useReducer(authReducer, initialState);
    const actions = useAuthActions(dispatch);
    const value = useMemo(() => ({ authState, actions }), [authState, actions]);
    console.log('🚀 ~ AuthProvider ~ value:', value);

    // Ensure `i18n` is initialized
    useEffect(() => {
        if (i18n.isInitialized && authState.language) {
            if (i18n.language !== authState.language) {
                i18n.changeLanguage(authState.language);
            }
        }
    }, [i18n, authState.language]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error(
            'useAuthContext must be used within a AuthProvider.'
        );
    }

    return context;
};

export { useAuthContext, AuthProvider };