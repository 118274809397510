import { actionQuestionTypeAddTypes } from 'constants/actionQuestionTypeAddTypes';

export const initialState = {
    showMainError: false,
    showLoading: false,
    status: 1,
    errors: [],
    label: [],
    slug: [],
    type: 'general',
};

export const questionTypeAddReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
        case actionQuestionTypeAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionQuestionTypeAddTypes.CLEAR_STATE:
            return initialState;
        case actionQuestionTypeAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionQuestionTypeAddTypes.SET_TYPE:
            return { ...state, type: action.payload };
        case actionQuestionTypeAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionQuestionTypeAddTypes.SET_QUESTION_TYPE_DATA:
            return {
                ...state,
                id: action.payload.id,
                status: action.payload.status,
                type: action.payload.type,
                label: action.payload.labelByLanguage,
                slug: action.payload.slugByLanguage,
            };
        case actionQuestionTypeAddTypes.SET_LABEL:
            return {
                ...state,
                label: {
                    ...state.label,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionQuestionTypeAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionQuestionTypeAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
    }
};